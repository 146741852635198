import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerUser implements ErrorHandler {
  public handleError(error: any): void {
    if (environment.production) {
      console.error(error ?? 'Erro desconhecido');
    }
  }
}
